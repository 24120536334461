"use strict";

/*--SLIDER--*/
let globalInter;

let sliderMove = function(direction='right'){
	let activeSlide = $('.slide.is-active');
	let firstSlide = $('.slide').first(); 
	let lastSlide = $('.slide').last(); 
	let nextSlide = activeSlide.next();
	let prevSlide = activeSlide.prev();

	activeSlide.removeClass('is-active');

	if (direction == 'right'){
		if (nextSlide.is('.slide')){
			nextSlide.addClass('is-active');
		}else{
			firstSlide.addClass('is-active');	
		}
	};

	if (direction == 'left'){
		if (prevSlide.is('.slide')){
			prevSlide.addClass('is-active');
		}else{
			lastSlide.addClass('is-active');	
		}
	}

	sliderInterval('right');
}

let sliderInterval = function(direction){
	clearInterval(globalInter);
	globalInter = setInterval(function() {
		sliderMove(direction);
	}, 8000);	
}

let sliderInit = function(){
	$('.slide').first().addClass('is-active');
	sliderInterval('right');
};

/*---SHIELD---*/

let messagePosition = function(){
	let messages = $('.shield.is-active .message');
	messages.each(function(){
		let message = $(this);
		let win = $(window);

		let diff = parseInt(win.height()) - parseInt(message.css('height'));
		
		if (diff > 0){
			message.css('top',(diff/2)+'px');	
		}else{
			message.css('top',0);	
		}
		
	});
};

let openShield = function(){
	$('.js-open-shield').click(function(){

		let opener = $(this);
		let dataShiled = $(this).attr('data-shield');

		let shield = $(`.shield[data-shield="${dataShiled}"]`);
		let shieldAfter = $(`.shield[data-shield="${dataShiled}"]:after`);
		let shieldBefore = $(`.shield[data-shield="${dataShiled}"]:before`);

		shieldAfter.height(shield.height());
		shieldBefore.height(shield.height());

		shield
			.addClass('is-active')
			.scrollTop(0);
		$(`body`).addClass('is-hidden');

		messagePosition();
	});
};

let closeShield = function(){
	
	$('.js-close-shield').click(function(e){

		e.stopPropagation();
		let closer = $(this);
		let dataShiled = $(this).attr('data-shield');

		$(`.shield[data-shield="${dataShiled}"]`)
			.removeClass('is-active')
		$(`body`).removeClass('is-hidden');
	});

};

/*---MAIL---*/



let mailNameCheck = function(form){

	let name = form.find('.js-mail-name');

	if (name.val().trim()){
		return true;
	};

	return false;

};

let mailPhoneCheck = function(form){

	let phone = form.find('.js-mail-phone');

	if (phone.val().trim()){
		return true;
	};

	return false;

};

let mailOfficeCheck = function(form){

	let flag = false;

	form.find('.js-mail-region').each(function(){
		if ($(this).prop("checked")){
			flag = true;
		};
	});

	return flag;

};

let mailClear = function(form){

	form.find('.js-mail-name').val('');
	form.find('.js-mail-phone').val('');
	form.find('.js-mail-address').val('');

};

let mailMessage = function(form, text){

	let f = form.find('.js-mail-message');
	f.text(text).css('opacity', 1);
	setTimeout(function() {
		f.animate({opacity : 0},300);	
	}, 2000)

	


};

let mailSend = function(form){

	let data = {
		'name' : form.find('.js-mail-name').val(),
		'phone' : form.find('.js-mail-phone').val(),
		'address' : form.find('.js-mail-address').val(),
		'office' : form.find('.js-mail-region:checked').parent().find('span').text()
	};

	$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('#token').attr('value')
        }
    });

	$.post( "/mail", data );


};

let mailInit = function(){

	$('form.js-mail').submit(function(e){
		e.preventDefault();
		if (mailNameCheck($(this)) && mailPhoneCheck($(this)) && mailOfficeCheck($(this))) {
			//alert('Сообщение отправлено');
			mailSend($(this));
			mailClear($(this));
			mailMessage($(this),'Заявка отправлена');
		}else{
			mailMessage($(this),'Заполните все поля');
		}
	});

};

/*---MAIN---*/

$(document).ready(function(){

	sliderInit();

	$('.js-slider-left').click(function(){
		sliderMove('left',globalInter);
	});

	$('.js-slider-right').click(function(){
		sliderMove('right',globalInter);
	});

	openShield();
	closeShield();

	mailInit();
	
});

$(window).resize(function(){

	messagePosition();
	
});